<template>
  <div>
    <el-card class="box-card" style="padding: 20px;">
      <div style="margin-top: -13px;">
        <el-form :rules="rules" :model="schoolFrom" ref="userSchoolForm">
          <el-row>
            <el-col :span="4">
              <div style="margin-bottom: 10px;float: left;margin-top: 12px;" class="box-title">用户管理</div>
            </el-col>
            <el-col :span="4" v-if="userRoleName == '超级管理员'">
              <el-form-item label="学校">
                <el-input v-model="schoolName" style="width: 80%;margin-right:10px" placeholder="请输入关键字" clearable size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="角色">
                <el-select v-model="userValueShare" style="width: 80%;margin-right:10px" placeholder="请输入关键字" clearable size="mini">
                  <el-option v-for="item in userOptions" :key="item.id" :label="item.roleName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="用户名称">
                <el-input v-model="roleName" style="width: 80%;margin-right:10px" placeholder="请输入关键字" clearable size="mini"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <div style="float: right;margin-top: 7px;">
                <el-button size="mini" type="primary" icon="el-icon-search" @click="getUserByName">搜索</el-button>
                <el-button type="primary" size="mini" @click="batchImport">批量导入</el-button>
                <el-button type="primary" size="mini" @click="downloadTemplate">导出模板</el-button>
              </div>
              
            </el-col>
            <el-dialog title="选择学校" v-model="dialogVisible" width="30%" :close-on-click-modal="false">

              <!-- <el-form-item >
              <el-upload style="display: inline-block;margin:0 10px" :on-change="handleChange" action="https://baidu.com/" :limit="1" :file-list="fileList" multiple :on-success="flieSuccess" :auto-upload="false" :headers="headers" :show-file-list="true">
                <el-button type="primary" style="margin-left: 0px; width: 50px;">上 传</el-button>
              </el-upload>
            </el-form-item> -->
              <el-form :rules="rules" :model="dataForm" ref="dataForms" label-width="80px">
                <el-form-item label="学校" prop="schoolNames">
                  <el-select v-model="dataForm.schoolNames" placeholder="请选择">
                    <el-option v-for="item in schoolNamess" :key="item.id" :label="item.schoolName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="文件" prop="file">
                  <span v-if="uploadLoading">{{ dataForm.file.name }} <el-button type="text" @click="clearFiles"><i class="el-icon-close"></i></el-button></span>
                  <el-upload class="upload-import" ref="uploadImport" action="" :on-remove="handleRemove" :show-file-list="false" :limit="1" :on-change="handleChange" :auto-upload="false" accept="application/xlsx,.xlsx">
                    <!-- 设置接收的文件类型-->
                    <!-- el-upload组件,在手动上传时,禁用按钮外, 还需要设置    :disabled="hasFile"   为true禁用该组件,会导致上传列表也被禁用,无法删除,因此使用v-show,文件数量为1时,显示禁用的的按钮, slot不绑定trigger事件 -->
                    <span v-show="!uploadLoading">
                      <el-button size="small" type="primary" style="position: absolute;left: 0;">选取文件</el-button>
                      <div style="margin-top: 40px;font-size: 6px;">只能上传xlsx文件，且仅支持上传一个文件</div>
                    </span>

                  </el-upload>
                </el-form-item>
                <el-button type="primary" @click="confirmImport" :disabled="uploadLoading2" :loading="uploadLoading2">确 定</el-button>
              </el-form>
            </el-dialog>

            <!-- <el-col :span="8">
          <el-switch class="register"  v-model="value" active-color="#13ce66" inactive-color="#ff4949" @click="control" inactive-text="注册开关：" style="position:absolute;right: 30px;top: 14px;" content="注册开关！" placement="top">
          </el-switch> -->
            <!-- </el-col> -->
            <!-- </div> -->
          </el-row>
        </el-form>
      </div>
      
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :beforeRouteName="beforeRouteName" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </el-card>

    <el-dialog title="设置角色" v-model="showRoleDia" width="30%">
      <span>
        <span> 角色：</span>
        <el-select v-model="roleNames" placeholder="请选择">
          <el-option v-for="item in userOptions" :key="item.id" :label="item.roleName" :value="item.id">
          </el-option>
        </el-select>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="backDia">取 消</el-button>
          <el-button type="primary" @click="saveInfo">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref, watch, nextTick, getCurrentInstance } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { userData } from "./userData";
import {
  clickButtons, currentChanges, sizeChanges, getUserLists,
  backInfo, saveInfos, controls, getUserByNames, downloadTemplates, getRoleList, batchImports, confirmImports
} from "./userMethods";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    const dataForms = ref(null)
    const userSchoolForm = ref(null)
    const { proxy } = getCurrentInstance();
    const uploadImport = ref(null);
    onMounted(() => {
      let token = sessionStorage.getItem("token")
      data.userRoleName = JSON.parse(sessionStorage.getItem("user")).userRoleName
      data.headers.Authorization = token
      getUserList()
      getRoleLists()
    })
    let data = reactive({
      showUpload: false,
      headers: {},
      drafrList: [
        { name: '学校', text: 'schoolName' },
        { name: '用户名称', text: 'name' },
        { name: '用户角色', text: 'roleName' },
        { name: '手机号码', text: 'phone' },
        { name: '学号', text: 'username' },
        { name: '创建时间', text: 'createTime' },
      ],
      userValueShare: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      loading: true,
      spreadTable: [],
      tableHeight: 400,
      operation: ['设置角色', '删除', '重置'],
      beforeRouteName: "userManage",
      pageType: 1,
      lastState: 1000,
      roleNames: '',
      roleName: '',
      schoolNames: '',
      schoolName: '',
      userInput: undefined,
      schoolId: '',
      showRoleDia: false,
      dialogVisible: false,
      userItem: {},
      userValue: '',
      userOptions: [],
      schoolNamess: [],
      value: true,
      userRoleName: '',
      schoolFrom: {
        schoolNames: ''
      },
      // rules: {
      //   schoolNames: [
      //     { required: true, message: '请选择学校', trigger: 'blur' }
      //   ],
      // },

      dataForm: {
        schoolNames: '',
        file: null
      },
      rules: {
        schoolNames: [
          { required: true, message: "请选择学校", trigger: "blur" }
        ],
        file: [
          { required: true, message: "请选择上传文件", trigger: "change" },
        ]
      },
      hasFile: false,
      uploadLoading: false,
      uploadLoading2: false,
      fileList: []
    })
    watch(() => [data.showUpload], ([newVal]) => {
      if (newVal) {
        data.showUpload = newVal
      }
    })
    let getUserList = () => { getUserLists(data) }
    let getRoleLists = () => { getRoleList(data) }
    let getUserByName = () => { getUserByNames(data) }

    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let backDia = () => { backInfo(data) }
    let saveInfo = () => { saveInfos(data) }
    let control = () => { controls(data) }
    let flieSuccess = () => { flieSucceses(response, data) }
    let downloadTemplate = () => { downloadTemplates(data) }
    let batchImport = () => { batchImports(uploadImport, data) }
    const resetForm = () => {
      dataForms.value.resetFields();
    }
    // const confirmImport = () =>{
    //   userSchoolForm.value.validate((valid) => {
    //     if(valid){
    //       data.showUpload = true
    //     }else{
    //       data.showUpload = false
    //     }
    //   })
    // }
    const clearFiles = () => {
      nextTick(() => {
        // proxy.$refs['uploadImport'].clearFiles();
        uploadImport.value.clearFiles()
        data.dataForm = {
          schoolNames: '',
          file: null
        },
          data.uploadLoading = false;
        data.uploadLoading2 = false;
      })
    }
    const confirmImport = () => {
      dataForms.value.validate((valid) => {
        if (valid) {
          let dataPar = data.dataForm;
          let fdParams = new FormData();
          fdParams.append('schoolId', dataPar.schoolNames);
          fdParams.append('file', dataPar.file.raw);
          data.uploadLoading2 = true
          confirmImports(fdParams, data)
        }
      })
    }
    // const getPolicy = () => {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/tradelab/sysUser/importUsersInBulk")
    //       .then((res) => {
    //         const { data } = res;
    //         if (data.code === 200) {
    //           url.value = `/tradelab/sysUser/importUsersInBulk${data.url}`;
    //           resolve();
    //         }
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // };

    const handleRemove = (file, fileList) => {
      if (!fileList.length) {
        data.uploadLoading = false;
      }
      data.dataForm.file = null;
    };
    const handleChange = (file, fileList) => {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        data.uploadLoading = true
      } else {
        data.uploadLoading = false
      }
      data.dataForm.file = file;
    }


    return {
      ...toRefs(data),
      handleRemove,
      handleChange,
      resetForm,
      getUserByName,
      confirmImport,
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      backDia,
      getRoleLists,
      saveInfo,
      control,
      flieSuccess,
      downloadTemplate,
      batchImport,
      userSchoolForm,
      dataForms,
      clearFiles,
      uploadImport
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
.search-name {
  font-size: 13px;
  font-weight: 400;
}
.register {
}
</style>
